import React, { useState } from 'react';
import {Upload, Modal, Button, Select, Tooltip, Icon, Form, Input, DatePicker, Layout, Space } from 'antd';
import SoundMeter from './soundmeter';
import PropTypes from 'prop-types';
import SettingsIcon from "mdi-react/SettingsIcon";
import "../../styles/css/setting.scss";
import { SearchOutlined } from '@ant-design/icons';
import axios from 'axios'

import moment from 'moment';
//视频
let video;

const Option = Select.Option;
const { Header, Content, Sider } = Layout;
const { Search } = Input;
const closeMediaStream = function (stream) {

    if (!stream) {
        return;
    }
    if (MediaStreamTrack && MediaStreamTrack.prototype && MediaStreamTrack.prototype.stop) {
        var tracks, i, len;

        if (stream.getTracks) {
            tracks = stream.getTracks();
            for (i = 0, len = tracks.length; i < len; i += 1) {
                tracks[i].stop();
            }
        } else {
            tracks = stream.getAudioTracks();
            for (i = 0, len = tracks.length; i < len; i += 1) {
                tracks[i].stop();
            }

            tracks = stream.getVideoTracks();
            for (i = 0, len = tracks.length; i < len; i += 1) {
                tracks[i].stop();
            }
        }
        // Deprecated by the spec, but still in use.
    } else if (typeof stream.stop === 'function') {
        console.log('closeMediaStream() | calling stop() on the MediaStream');
        stream.stop();
    }
}

// Attach a media stream to an element.
const attachMediaStream = function (element, stream) {
    element.srcObject = stream;
};

export default class Report extends React.Component {
   
    constructor(props) {

        

        super(props)
        let settings = props.settings;
        this.state = {
           
           
          
           streams: [],
		   tex:"2qq",
		   imageUrl1:'',
		   imageUrl2:'',
		   
			formData:{},
            visible: false,
            videoDevices: [],
            audioDevices: [],
            audioOutputDevices: [],
            resolution: settings.resolution,
            bandwidth: settings.bandwidth,
            selectedAudioDevice: settings.selectedAudioDevice,
            selectedVideoDevice: settings.selectedVideoDevice,
            selectedVideoDeviceLocal: settings.selectedVideoDeviceLocal,
            codec: settings.codec,
            searchTerm: '',
            setSearchTerm: ''

        }
		 this.timerID2 = null; // 存储定时器ID
        try {
            window.AudioContext = window.AudioContext || window.webkitAudioContext;
            window.audioContext = new AudioContext();
        } catch (e) {
            console.log('Web Audio API not supported.');
        }
        let fileList = []
        const { imgs } = this.props
        if (imgs && imgs.length>0) {
          fileList = imgs.map((img, index) => ({
            uid: -index, 
            name: img, 
            status: 'done', 
            url: BASE_IMG_URL + img
          }))
        }
         


    }
	 startTimer() {
	    this.timerID2 = setInterval(
	      () => {
			  this.timerID2 = setInterval(
			      () => {
			  				 const api = axios.create({
			  				   baseURL: 'https://amanage.xytechsz.com/manage1'
			  				 });
			  						
			  				 api.get('/report/getReportData',{
			  								  params: {
			  									pathologyNumber: 'J0001',
			  									roomName: 'default'
			  								  }})
			  				     .then(response => {
			  				       // 处理请求成功的逻辑
			  							    this.setState({ formData: response.data.data });
			  				       console.log(this.state.formData);
			  				       console.log(this.state.formData.reportTime);
			  				       console.log(this.state.formData.inspectionTime);
			  							   // this.state.formData.reportTime
			  				     })
			  				     .catch(error => {
			  				       // 处理请求失败的逻辑
			  				       console.error(error);
			  				     }); 
			  			  },
			      6000
			  );
		  },
	      1000
	    );
	  }
	
	  // 停止计时器
	  stopTimer() {
	    clearInterval(this.timerID2);
	  }
	
	  // 暂停计时器
	  pauseTimer() {
	    clearInterval(this.timerID2);
	  }
	
	  // 继续计时器
	  resumeTimer() {
	    this.startTimer(); // 从 tick() 继续计时
	  }
	componentDidMount(){
	     const api = axios.create({
	       baseURL: 'https://amanage.xytechsz.com/manage1'
	     });
	     			  						
	     api.get('/report/getReportData',{
	     			  								  params: {
	     			  									pathologyNumber: 'J0001',
	     			  									roomName: 'default'
	     			  								  }})
	         .then(response => {
	           // 处理请求成功的逻辑
	     			  							    this.setState({ formData: response.data.data });
	           console.log(this.state.formData);
	           console.log(this.state.formData.reportTime);
	           console.log(this.state.formData.inspectionTime);
	     			  							   // this.state.formData.reportTime
	         })
	         .catch(error => {
	           // 处理请求失败的逻辑
	           console.error(error);
	         }); 
		  // this.startTimer()
		  
	  }
    updateInputDevices = () => {
        return new Promise((pResolve, pReject) => {
            let videoDevices = [];
            let audioDevices = [];
            let audioOutputDevices = [];
            navigator.mediaDevices.enumerateDevices()
                .then((devices) => {
                    for (let device of devices) {
                        if (device.kind === 'videoinput') {
                            videoDevices.push(device);
                        } else if (device.kind === 'audioinput') {
                            audioDevices.push(device);
                        } else if (device.kind === 'audiooutput') {
                            audioOutputDevices.push(device);
                        }
                    }
                }).then(() => {
                    let data = { videoDevices, audioDevices, audioOutputDevices };
                    pResolve(data);
                });
        });
    }

   
	//获取视频成功
	handleSuccess = (stream) => {
		console.log('navigator.MediaDevices.getUserMedia error: ', error.message, error.name);
	  window.stream = stream;
	  //将视频源指定为视频流
	  video.srcObject = stream;
	}
	//错误处理
	handleError(error) {
	  console.log('navigator.MediaDevices.getUserMedia error: ', error.message, error.name);
	}
    soundMeterProcess = () => {
        var val = (window.soundMeter.instant.toFixed(2) * 348) + 1;
        this.setState({ audioLevel: val });
        if (this.state.visible)
            setTimeout(this.soundMeterProcess, 100);
    }
    soundMeterProcess2 = () => {
        var val = (window.soundMeter.instant.toFixed(2) * 348) + 1;
        this.setState({ audioLevel: val });
        if (this.state.visible)
            setTimeout(this.soundMeterProcess2, 100);
    }
    startPreview = () => {
        if (window.stream) {
            // closeMediaStream(window.stream);
        }
        let videoElement = this.refs['previewVideo'];
        let audioSource = this.state.selectedAudioDevice;
        let videoSource = this.state.selectedVideoDevice;


        sessionStorage.setItem('audioSource', audioSource);
        sessionStorage.setItem('videoSource', videoSource);

        this.soundMeter = window.soundMeter = new SoundMeter(window.audioContext);
        let soundMeterProcess = this.soundMeterProcess;
        let constraints = {
            audio: { deviceId: audioSource ? { exact: audioSource } : undefined },
            video: { deviceId: videoSource ? { exact: videoSource } : undefined }
        };
        navigator.mediaDevices.getUserMedia(constraints)
            .then(function (stream) {
                window.stream = stream; // make stream available to console
                //videoElement.srcObject = stream;
                attachMediaStream(videoElement, stream);
                soundMeter.connectToSource(stream);
                setTimeout(soundMeterProcess, 100);
                // Refresh button list in case labels have become available
                return navigator.mediaDevices.enumerateDevices();
            })
            .then((devces) => { })
            .catch((erro) => { });
    }
    startPreview2 = () => {
        if (window.stream) {
            // closeMediaStream(window.stream);
        }

        let videoElement2 = this.refs['previewVideo2'];
        let videoSource = this.state.selectedVideoDeviceLocal;
        let selectedVideoDeviceLocal = this.state.selectedVideoDeviceLocal;
        sessionStorage.setItem('selectedVideoDeviceLocal', selectedVideoDeviceLocal);
        this.soundMeter = window.soundMeter = new SoundMeter(window.audioContext);
        let soundMeterProcess = this.soundMeterProcess2;
        let constraints = {
            video: { deviceId: videoSource ? { exact: videoSource } : undefined }
        };
        navigator.mediaDevices.getUserMedia(constraints)
            .then(function (stream) {
                window.stream = stream; // make stream available to console
                //videoElement.srcObject = stream;

                attachMediaStream(videoElement2, stream);
                soundMeter.connectToSource(stream);
                setTimeout(soundMeterProcess, 100);
                // Refresh button list in case labels have become available
                return navigator.mediaDevices.enumerateDevices();
            })
            .then((devces) => { })
            .catch((erro) => { });
    }
    stopPreview = () => {
        if (window.stream) {
            closeMediaStream(window.stream);
        }
    }

    componentWillUnmount() {

    }

    showModal = () => {
        this.setState({
            visible: true,
        });
        setTimeout(this.startPreview, 100);
        setTimeout(this.startPreview2, 100);
    }

    handleOk = (e) => {

        this.setState({
            visible: false,
        });



    }
	
	
    handleCancel = (e) => {
        this.setState({
            visible: false,
        });
        this.stopPreview();
    }

    handleAudioDeviceChange = (e) => {
        this.setState({ selectedAudioDevice: e });
        setTimeout(this.startPreview, 100);
    }

    handleVideoDeviceChange = (e) => {
        this.setState({ selectedVideoDevice: e });
        setTimeout(this.startPreview, 100);
    }
    handleVideoDeviceLocalChange = (e) => {
        this.setState({ selectedVideoDeviceLocal: e });
        setTimeout(this.startPreview2, 100);
    }
    handleResolutionChange = (e) => {
        this.setState({ resolution: e });
    }

    handleVideoCodeChange = (e) => {
        this.setState({ codec: e });
    }

    handleBandWidthChange = (e) => {
        this.setState({ bandwidth: e });
    }
    onFinish = (values) => {
        console.log('Received values from form:', values);
        // 在这里可以对表单提交的值进行处理，比如发送给后端处理
    };
    handleInputChange1 = (values) => {
        console.log('Received values from form:', values);
        // 在这里可以对表单提交的值进行处理，比如发送给后端处理
    };
    handleSearch = (values) => {
        console.log('Received values from form:', values);
        // 在这里可以对表单提交的值进行处理，比如发送给后端处理
    };
   
    
      getImgs = () => {
        return this.state.fileList.map(file => file.name)
      }
    
      handleCancel = () => this.setState({
            visible: false,
        });
    
    //截屏处理
    takeSnap1 = async (e) => {
		 this.props.getImage();
			 this.setState({
			      imageUrl1: sessionStorage.getItem("base64Image")
			    });
    }  
     //截屏处理
     takeSnap2 = async (e) => {
     	this.props.getImage();
     				 this.setState({
     				      imageUrl2: sessionStorage.getItem("base64Image")
     				    });
     }   
	 handleChange= () => {
		 console.log("修改")
		 
		 this.pauseTimer()
	 }
	 handleTxt= (e) => {
		    this.setState({tex : e.target.value})
      
	 }
    render() {
        
    
        return (
            <div>
                {
                    <Tooltip title='发送报告'>
                        <Button size="large" type="link" ghost onClick={this.showModal}>
                            <Icon
                                component={SettingsIcon}
                                style={{ display: "flex", justifyContent: "center" }}
                            />
                            <p	>发送报告</p>
                        </Button>
                    </Tooltip>
                }
                <Modal
                    title='发送报告'
                    visible={this.state.visible}
                   
					
                    width="80%"
                    className="full-modal"
					footer={[
					        <Button key='cancel' onClick={this.handleCancel} >取消</Button>,
					        <Button key='continue' onClick={this.handleChange}>修改</Button>,
					        <Button key='confirm' type="primary" onClick={this.handleOk}>确定</Button>
					      ]}
						
                >


                    <Layout>

                        <Layout>

                            <Sider width={200} theme="light">
                                <div className='leftmenu'>

                                    <input
                                        className='inputSearch'
                                        type="text"
                                        value={this.state.searchTerm}
                                        onChange={this.handleInputChange1}
                                        placeholder="请输入搜索内容"
                                    />
                                    <Button className='btnSearch' type="primary" size='small'  >查询</Button>

                                </div>
                            </Sider>
                            <Layout>
                                <Content style={{ padding: '24px' }}>
                                    <Form layout="vertical">
                                        <table>

                                            <tbody>
                                                <tr>
                                                    <td>病理号</td>
                                                    <td className="custom-td">
                                                        <input type="text"   value={this.state.tex} onChange={this.handleTxt} placeholder="请输入病理号" width="100px" />
                                                    </td>

                                                </tr>
                                                <tr>
                                                    <td>姓名</td>
                                                    <td className="custom-td">
                                                        <input type="text"  value={this.state.formData.patientName}  placeholder="请输入姓名" width="100px" />
                                                    </td>
                                                    <td>性别</td>
                                                    <td className="custom-td">
                                                        <input type="text"  value={this.state.formData.patientSex}  placeholder="请输入姓名性别" width="100px" />
                                                    </td>
                                                    <td>年龄</td>
                                                    <td className="custom-td">
                                                        <input type="text"  value={this.state.formData.patientAge}  placeholder="请输入年龄" width="100px" />
                                                    </td>
                                                    <td>送检日期</td>
                                                    <td className="custom-td">
                                                        <DatePicker  defaultValue={moment( new Date()  )}  placeholder="请选择送检日期" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>门诊/住院号</td>
                                                    <td className="custom-td">
                                                        <input type="text"  value={this.state.formData.outpatientNumber}  placeholder="请输入门诊/住院号" width="100px" />
                                                    </td>
                                                    <td>床号</td>
                                                    <td className="custom-td">
                                                        <input type="text"  value={this.state.formData.bedNumber}  placeholder="请输入床号" width="100px" />
                                                    </td>
                                                    <td>送检科室</td>
                                                    <td className="custom-td">
                                                        <input type="text"  value={this.state.formData.inspectionDepartment}  placeholder="请输入送检科室" width="100px" />
                                                    </td>
                                                    <td>报告日期</td>
                                                    <td className="custom-td">
                                                        <DatePicker   defaultValue={moment(this.state.formData.reportTime )} placeholder="请选择报告日期" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>临床诊断</td>
                                                    <td className="custom-td">
                                                        <input type="text"  value={this.state.formData.clinicDiagnosis}  placeholder="请输入临床诊断" width="100px" />
                                                    </td>
                                                    <td>送检医生</td>
                                                    <td className="custom-td">
                                                        <input type="text"  value={this.state.formData.inspectionDoctor}  placeholder="请输入送检医生" width="100px" />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <Form.Item style={{ marginTop: "10px", }} label="病例图像" name="diagnosis" rules={[{ required: true, message: '请输入诊断信息' }]}>
											<img className="small-canvas1" src={this.state.imageUrl1}  ref='canvas1' />
                                            <Button className="button"   onClick={this.takeSnap1}>截屏</Button>
											<img className="small-canvas1" src={this.state.imageUrl2}  ref='canvas2'/>
											<Button className="button" onClick={this.takeSnap2}>截屏</Button>
                                        </Form.Item>
                                        <Form.Item style={{ marginTop: "100px", }} label="大体检查" name="diagnosis" rules={[{ required: true, message: '请输入诊断信息' }]}>
                                            <Input.TextArea  value={this.state.formData.grossExamination}  placeholder="请输入诊断信息" rows={8} />
                                        </Form.Item>
                                        <Form.Item label="镜下所见" name="diagnosis" rules={[{ required: true, message: '请输入诊断信息' }]}>
                                            <Input.TextArea  value={this.state.formData.microscopeSee}  placeholder="请输入诊断信息" rows={8} />
                                        </Form.Item>
                                        <Form.Item label="病理诊断" name="diagnosis" rules={[{ required: true, message: '请输入病理诊断信息' }]}>
                                            <Input.TextArea  value={this.state.formData.pathologicDiagnosis}  placeholder="请输入病理诊断" rows={8} />
                                        </Form.Item>

                                    </Form>
                                </Content>
                            </Layout>
                        </Layout>
                    </Layout>



                </Modal>
            </div>
        );
    }
}


Report.propTypes = {
    onMediaSettingsChanged: PropTypes.func
}



