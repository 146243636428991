import React from "react";
import { Spin, notification } from "antd";
import "../styles/css/meeting.scss";

import { LocalVideoComponent, BigVideoComponent, SmallVideoComponent } from "./video";
import { LocalStream } from "ion-sdk-js";

const MyContext = React.createContext();
class MeetingPage extends React.Component {
	 static contextType = MyContext;
    constructor(props) {
		super(props)
		
        this.state = {
            streams: [],
            localStream: null,
            localScreen: null,
            audioMuted: false,
            videoMuted: false,
			date: new Date(),
			displayMainVideo:true
         };
    	
    }
     handleshow() {
            this.setState({
                displayMainVideo:true
            })
        }
        handlehide() {
            this.setState({
                displayMainVideo:false
            })
        }
    
	 componentDidMount() {
	    this.timerID = setInterval(
	      () => this.tick(),
	      1000
	    );
	  }
	 tick() {
		 let { localStream, streams } = this.state;
		
		 streams.map(async item => {
			 item.name=sessionStorage.getItem(item.id)?sessionStorage.getItem(item.id):""
		 });
	     this.setState({
	       streams:streams
	     });
	   }
	  componentWillUnmount() {
	    clearInterval(this.timerID);
	  }
    cleanUp = async () => {
		
        let { localStream, streams } = this.state;
        await this.setState({ localStream: null, localScreen: null, streams: [] });

        streams.map(async item => {
            await item.stream.unsubscribe();
        });

        await this.unpublish(localStream);
    }

    unpublish = async stream => {
        if (stream) {
            await this.stopMediaStream(stream);
            await stream.unpublish();
        }
    }

    stopMediaStream = async (stream) => {
        let tracks = stream.getTracks();
        for (let i = 0, len = tracks.length; i < len; i++) {
            await tracks[i].stop();
        }
    }

    muteMediaTrack = (type, enabled) => {
        let { localStream } = this.state;
        if (!localStream) {
            return
        }
        if (enabled) {
            localStream.unmute(type)
        } else {
            localStream.mute(type)
        }

        if (type === "audio") {
            this.setState({ audioMuted: !enabled });
        } else if (type === "video") {
            this.setState({ videoMuted: !enabled });
        }
    }

    publishLocalStream = async () => {
        const { client, settings } = this.props;
        let { localStream } = this.state;
		
        let streams = this.state.streams;
		client.ontrack = (track, stream) => {
		    console.log("go track", track.id, "for stream", stream.id);
		    track.onunmute = () => {
		        console.log("onunmute:::" + stream.id);
		        if (track.kind === "video") {
		            if (!this.hasStream(stream)) {
		                let name ="";
		                streams.push({ id: stream.id, stream: stream, name: name });
		                this.setState({ streams });
			
		                stream.onremovetrack = () => {
		                    console.log("onremovetrack::" + stream.id);
		                    streams = streams.filter(item => item.id !== stream.id);
		                    this.setState({
		                        streams: streams,
		                    });
		                }
		            }
		        }
		        this.updateMuteStatus(stream, false);
		    };
			
		    track.onmute = () => {
		        console.log("onmute:::" + stream.id);
		        this.updateMuteStatus(stream, true);
		    }
		}
		LocalStream.getUserMedia({
		    codec: settings.codec.toUpperCase(),
		    // resolution: settings.resolution,
		    bandwidth: settings.bandwidth, 
			   // audio:true,
			   // video:{  width: 1920, height: 1080 },
		    audio: {deviceId:settings.selectedAudioDevice ?{ exact: settings.selectedAudioDevice }: undefined},
			// video: {width: 1920, height: 1080,deviceId:"41956b7ca0aba0582fa387e84d1afe3dfb73906adf7ba5ed303afb8250b8f498" },
		    video: {width: 1920, height: 1080,deviceId:settings.selectedVideoDevice ?{ exact: settings.selectedVideoDevice }: undefined },
		    // video: {width: 1920, height: 1080,deviceId: sessionStorage.getItem("videoSource") ? { exact: sessionStorage.getItem("videoSource") } : videoDevices[0].deviceId },
		    // {  width: 1920, height: 1080 }
			name:sessionStorage.getItem('userName')?sessionStorage.getItem('userName'):""
		}).then((stream) => {
			// let aas=sessionStorage.getItem('userName')?sessionStorage.getItem('userName'):""
			// stream.id=stream.id.toString()+'AAA' + aas.toString()
			if(stream.id){
				let streamsobj1={
					streamid:stream.id,
					userName:sessionStorage.getItem('userName')?sessionStorage.getItem('userName'):""
				}
				sessionStorage.setItem("streamsobj1",JSON.stringify(streamsobj1))
			}
		    localStream = stream;
		    client.publish(stream);
		    this.setState({ localStream });
			})
		    .catch((e) => {
				
		        console.log("handleLocalStream error => " + settings.selectedAudioDevice+settings.selectedVideoDevice);
		        console.log("handleLocalStream error => " + e);
		    });
			
		this.muteMediaTrack("video", this.props.localVideoEnabled);
		
        client.ontrack = (track, stream) => {
			console.log("go track111",track);
			console.log("go track",stream);
            console.log("go track", track.id, "for stream", stream.id);
            track.onunmute = () => {
                console.log("onunmute:::" + stream.id);
				console.log(track.kind)
                if (track.kind === "video") {
                    if (!this.hasStream(stream)) {
                         // let name =sessionStorage.getItem('userName')?sessionStorage.getItem('userName'):"";
                         // let name =stream.constraints.codec?stream.constraints.codec:stream.id;
						 console.log("stream2")
						 console.log(stream)
                        streams.push({ id: stream.id, stream: stream, name: name });
                        this.setState({ streams });

                        stream.onremovetrack = () => {
                            console.log("onremovetrack::" + stream.id);
                            streams = streams.filter(item => item.id !== stream.id);
                            this.setState({
                                streams: streams,
                            });
                        }
                    }
                }
				else if (track.kind === "audio") {
					navigator.mediaDevices.getUserMedia({audio: true}).then(localMicStream => {
					             
									
					                // screenStream.addTrack(mixedOutput.stream.getTracks()[0])
					                // handleStream(screenStream)
					           
					        }).catch(e => {
					            console.error(e)
					        })
					
					
				//     if (!this.hasStream(stream)) {
				//         let name = '参会者';
				//         streams.push({ id: stream.id, stream: stream, name: name });
				//         this.setState({ streams });
				
				//         stream.onremovetrack = () => {
				//             console.log("onremovetrack::" + stream.id);
				//             streams = streams.filter(item => item.id !== stream.id);
				//             this.setState({
				//                 streams: streams,
				//             });
				//         }
				//     }
				}
                this.updateMuteStatus(stream, false);
            };

            track.onmute = () => {
                console.log("onmute:::" + stream.id);
                this.updateMuteStatus(stream, true);
            }
        }
        LocalStream.getUserMedia({
            codec: settings.codec.toUpperCase(),
            // resolution: settings.resolution,
            bandwidth: settings.bandwidth, 
        	// audio:true,
        	// video:{  width: 1920, height: 1080 },
            audio: false,
            // audio: false,
			video: {width: 2592, height: 2048,deviceId:settings.selectedVideoDeviceLocal ?{ exact: settings.selectedVideoDeviceLocal }: undefined },
            
			
			// video: {width: 1920, height: 1080,deviceId:"afe8daa7dc63b161557b6c5b3e35bd71ecdb6c5e2babbf34ce6665950dfaaddd" },
            // video: {width: 1920, height: 1080,deviceId: sessionStorage.getItem("videoSource") ? { exact: sessionStorage.getItem("videoSource") } : videoDevices[0].deviceId },
            // {  width: 1920, height: 1080 }
        }).then((stream) => {
			 let name ="";
            // localStream = stream;
			if(stream.id){
				let streamsobj2={
					streamid:stream.id,
					userName:sessionStorage.getItem('userName')?sessionStorage.getItem('userName'):""
					
				}
				sessionStorage.setItem("streamsobj2",JSON.stringify(streamsobj2))
			}
			
            client.publish(stream);
            // this.setState({ localStream });
			sessionStorage.setItem("streamId",stream.id)
			streams.push({ id: stream.id, stream: stream, name: name });
			this.setState({ streams });
        })
            .catch((e) => {
				
                console.log("handleLocalStream error => " + settings.selectedAudioDevice+settings.selectedVideoDevice);
                console.log("handleLocalStream error => " + e);
            });

        this.muteMediaTrack("video", this.props.localVideoEnabled);

    }
	
    handleScreenSharing = async (enabled) => {
            let { localScreen, streams } = this.state;
            const { client, settings, screenSharingCallBack } = this.props;
			let aa=sessionStorage.getItem("userName")? sessionStorage.getItem("userName"): "admin";
			let name=aa+"分享屏幕"
            if (enabled) {
                localScreen = await LocalStream.getDisplayMedia({
                    codec: settings.codec.toUpperCase(),
                    // resolution: settings.resolution,
					bandwidth: settings.bandwidth,
					audio: false,
                });
                await client.publish(localScreen);
                let track = localScreen.getVideoTracks()[0];
                if (track) {
                    track.addEventListener("ended", () => {
                        screenSharingCallBack(false);
                        this.handleScreenSharing(false);
                    });
                }
				
                streams.push({ id: localScreen.id, stream: localScreen, name: name, muted: false });
				
            } else {
				
                if (localScreen) {
					
                    this.unpublish(localScreen);
                    streams = streams.filter(item => item.name.indexOf("分享屏幕") != -1);
                    localScreen = null;
                }
            }
            this.setState({
                streams: streams,
                localScreen: localScreen,
            })
			if(localScreen.id){
				let streamslocal={
					streamid:localScreen.id,
					userName:sessionStorage.getItem('userName')?sessionStorage.getItem('userName'):""
					
				}
				
				sessionStorage.setItem("streamslocal",JSON.stringify(streamslocal))
			}
        }

    //是否有相同的流
    hasStream = (stream) => {
        let flag = false;
        let streams = this.state.streams;
        streams.forEach((item) => {
            if (item.id == stream.id) {
                flag = true;
            }
        });
        return flag;
    }

    updateMuteStatus = (stream, muted) => {
        let streams = this.state.streams;
		console.log(streams)
        streams.forEach((item) => {
            if (item.id == stream.id) {
                item.muted = muted;
                
            }
        });
        this.setState({
            streams: streams,
        });
		
    }
	onChangeVideoLocalPosition= data => {
		    const { settings } = this.props;
		if(this.props.onMediaSettingsChanged !== undefined) {
			console.log(settings)
		    this.props.onMediaSettingsChanged(
		       settings.selectedAudioDevice,
		       settings.selectedVideoDeviceLocal,
			    settings.selectedVideoDevice,
				settings.resolution,
				settings.bandwidth,
		        settings.codec);
		}
		history.go(0)
	}
	onChangeVideoPositionSam(id){
	    const streams = this.state.streams;
	    let first = 0;
	    let big = 0;
	    for (let i = 0; i < streams.length; i++) {
	        let item = streams[i];
	        if (item.id == id) {
	            big = i;
	            break;
	        }
	    }
	
	    let c = streams[first];
	    streams[first] = streams[big];
	    streams[big] = c;
	
	    this.setState({ streams: streams });
	};
    onChangeVideoPosition = data => {

        let id = data.id;
        let index = data.index;
        console.log("onChangeVideoPosition id:" + id + " index:" + index);

        if (index == 0) {
            return;
        }

        const streams = this.state.streams;
        let first = 0;
        let big = 0;
        for (let i = 0; i < streams.length; i++) {
            let item = streams[i];
            if (item.id == id) {
                big = i;
                break;
            }
        }

        let c = streams[first];
        streams[first] = streams[big];
        streams[big] = c;

        this.setState({ streams: streams });
    };

    notification = (message, description) => {
        notification.info({
            message: message,
            description: description,
            placement: "bottomRight"
        });
    };
	onSendMessage= (envet) => {
        this.props.onSendMessage(envet)
    }
    render = () => {
        const { vidFit } = this.props;
        const {
            streams,
            localStream,
            audioMuted,
            videoMuted,
        } = this.state;
        const id = this.props.uid;

        return (
            <div className="meeting-layout">
                {streams.length === 0 && (
                    <div className="meeting-layout-wating">
                        <Spin size="large" tip="等待其他人加入会议..." />
                    </div>
                )

                }
                {streams.map((item, index) => {
                    return index ==0 ? (
                        <BigVideoComponent
							onSendMessage={this.onSendMessage}
                            key={item.id}
                            id={item.id}
                            name={item.name}
                            stream={item.stream}
                            muted={item.muted}
                            vidFit={vidFit}
							ref={ref => {
							    this.bigVideo = ref;
							}}
                        />
                    ) : (
                        ""
                    );
                })
                }
                {localStream && (
                    <div className="meeting-local-video-layout" onClick={this.onChangeVideoLocalPosition}>
                        <LocalVideoComponent
                            id={id + "-video"}
                            stream={localStream}
                            name="本地"
                            audioMuted={audioMuted}
                            videoMuted={videoMuted}
							ref={ref => {
							    this.localVideo = ref;
								
								
								
								    
							}}
                        />
						
						
						
						
						
						
						
                    </div>
                )
				
                }
                <div  className={this.state.displayMainVideo?"small-video-list-div":"small-video-list-div2"}  >
                                    <div className="small-video-list">
                                        {
                                            streams.map((item, index) => {
                                                return index > 0 ? (
                                                    <SmallVideoComponent
                                                        key={item.id}
                                                        id={item.id}
                                                        stream={item.stream}
                                                        name={item.name}
                                                        muted={item.muted}
                                                        videoCount={streams.length}
                                                        collapsed={this.props.collapsed}
                                                        index={index}
                                                        onClick={this.onChangeVideoPosition}
														ref={ref => {
														    this.smallVideo = ref;
														}}
                                                    />
                                                ) : (
                                                    ""
                                                );
                                            })}
                
                
                                    </div>
                                </div>
            </div>
        )
    }
}

export default MeetingPage;
